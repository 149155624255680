import "./bonenutmain.css";
import React, { useState, useEffect } from 'react';
import { LuChevronRightCircle } from "react-icons/lu";
import { TiStarOutline } from "react-icons/ti";
import { Link, useLocation } from "react-router-dom";
import { OurProductData } from "../../../utiles/Data";
import AOS from 'aos';
import 'aos/dist/aos.css';

const InsideProductPage = () => {
  const newlocation = useLocation();
  const searchParams = new URLSearchParams(newlocation.search);
  const slug = searchParams.get("slug");
  const newfilteredProperties = OurProductData.filter(
    (products) => products.slug === slug
  );
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, []);
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div>
      <header className="bg-[#F3C825] text-white text-center py-8">
        <h1 className="text-4xl font-bold text-black " data-aos="fade-right">{newfilteredProperties[0].name}</h1>
      </header>
      <div className="bg-black py-4">
        {newfilteredProperties.map((item, index) => (
          <div className="grid grid-cols-12 mt-8 gap-4 mx-auto w-[90%]">
            {item.OurProductInsideData.map((data,i) => (
              <div className="lg:col-span-4 md:col-span-6 sm:col-span-12 mb-6" key={data.id}
              data-aos={
                i % 3 === 0 ? "fade-right" : i % 3 === 1 ? "fade-down" : "fade-left"
              }
              >
                <div className="max-w-sm bg-white border box-shadow-bg border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 h-full flex flex-col justify-between">
                  <Link to={data.insidelink}>
                    <img className="rounded-t-lg h-[50vh] w-full mx-auto bg-[#E3E3E3] product-transition" src={data.img} alt={data.name} />
                  </Link>
                  <div className="px-5 pt-5">
                    <Link to={data.insidelink}>
                      <h5 className="mb-2 text-xl text-start font-semibold tracking-tight flex text-[#FF6609E0] dark:text-white">
                        {data.name}
                        <span className='ml-4 text-3xl'><LuChevronRightCircle /></span>
                      </h5>
                      <p className="flex text-sm pb-2">
                        <TiStarOutline className="mr-2 mt-1 text-[#FF6609E0]" />
                        {data.subname}
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default InsideProductPage;
