import "./bonenutmain.css";
import React, { useState, useEffect } from 'react';
import { LuChevronRightCircle } from "react-icons/lu";
import { TiStarOutline } from "react-icons/ti";
import { useNavigate } from 'react-router-dom';
import { OurProductData } from "../../../utiles/Data";
import AOS from 'aos';
import 'aos/dist/aos.css';
const NewSearch = () => {
  const navigate = useNavigate();
  const handleClick = (product) => {
    if (product.id >= 7) {
      navigate(product.link);
    } else {
      navigate(`/our-products-details?slug=${product.slug}`);
    }
  };
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [])
  useEffect(() => {
    AOS.init({
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);
  return (
    <div>
      <header className="bg-[#F3C825] text-black text-center py-8">
        <h1 className="text-4xl font-semibold" data-aos="fade-left">Our Products</h1>
        <p className="mt-2 text-lg w-[80%] mx-auto capitalize" data-aos="fade-right">
          Discover an exquisite collection of bone and horn handicrafts, from timeless statement pieces to everyday essentials and elegant decor for every occasion</p>
      </header>
      <div className="bg-black py-4">
        <div className="grid grid-cols-12 mt-8 gap-4 mx-auto w-[90%]">
          {OurProductData.map((product,i) => (
            <div className="lg:col-span-4 md:col-span-6 sm:col-span-12 mb-6" key={product.id} 
            data-aos={
              i % 3 === 0 ? "fade-right" : i % 3 === 1 ? "fade-down" : "fade-left"
            }
            >
            <div className="max-w-sm bg-white border border-gray-200 rounded-lg shadow box-shadow-bg dark:bg-gray-800 dark:border-gray-700 h-full flex flex-col justify-between">
              <img
                className="rounded-t-lg h-[50vh] w-full mx-auto bg-[#E3E3E3] product-transition cursor-pointer"
                src={product.img}
                alt={product.name}
                onClick={() => handleClick(product)}
              />
              <div className="p-5">
                <>
                  <h5
                    className=" text-xl cursor-pointer text-center font-semibold tracking-tight text-[#FF6609E0] flex justify-center items-center dark:text-white"
                    onClick={() => handleClick(product)}
                  >
                    {product.name}
                    <span className="ml-4 text-3xl">
                      <LuChevronRightCircle />
                    </span>
                  </h5>
                  <p className="flex text-sm pb-0">
                    <TiStarOutline className="mr-2 mt-1 text-[#FF6609E0]" />
                    {product.subname}
                  </p>
                </>
              </div>
            </div>
          </div>
          
          ))}
        </div>
      </div>
    </div>
  );
};

export default NewSearch;

