






//                         {t("Let's Connect With Us")}





//                                     placeholder={t('*Your Name')}

//                                         required: t("Full Name is Required"),
//                             <div>
//                                 <input
//                                     autocomplete="off"
//                                     type="email"
//                                     placeholder={t('*E-Mail')}
//                                     id="email_address"
//                                     name="email"
//                                     {...register("email", {
//                                         required: t("Enter your email"),
//                                         pattern: {
//                                             value: /^\S+@\S+$/i,
//                                             message: t("Please enter a valid email"),
//                                         },
//                                     })}
//                                     className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none border border-white bg-blue-800 rounded w-[100%] py-3 px-2 text-white leading-tight focus:outline-none focus:shadow-outline"
//                                 />
//                                 {errors.email && (
//                                     <div className="mt-1 text-white ml-2">
//                                         <small>{errors.email.message}</small>
//                                     </div>
//                                 )}
//                             </div>
//                         </div>
//                         <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-3">
//                             <div>
//                                 <input
//                                     autocomplete="off"
//                                     type="tel"
//                                     id="phone"
//                                     placeholder={t('*Your Phone Number')}
//                                     name="phone"
//                                     {...register("phone", {
//                                         required: t("Phone number is required"),
//                                     })}
//                                     className="shadow placeholder:text-white focus:ring-0 focus:border-white text-white appearance-none border border-white bg-blue-800 rounded w-[100%] py-[10px] px-2 "
//                                 />
//                             </div>
//                             <div>
//                                 <input
//                                     type="text"
//                                     placeholder={t('*Organization Name')}
//                                     id="name"
//                                     {...register("Organization Name", {
//                                         maxLength: 50,
//                                     })}
//                                     className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
//                                 />
//                             </div>
//                             <div>
//                                 <input
//                                     type="text"
//                                     placeholder={t('*Products/Service Looking For')}
//                                     id="name"
//                                     {...register("products", {
//                                         maxLength: 50,
//                                         message: t("Please enter your products"),
//                                     })}
//                                     className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
//                                 />
//                                 {errors.products && (
//                                     <div className="mt-1 text-red-600">
//                                         <small>{errors.products.message}</small>
//                                     </div>
//                                 )}
//                             </div>
//                             <div>
//                                 <select
//                                     placeholder={t("*Select Your Country")}
//                                     className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
//                                 >
//                                     <option value="">{t('*Select Your Country')}</option>
//                                     <option value="">{t('India')}</option>
//                                     <option value="">{t('United States')}</option>
//                                     <option value="">{t('Japan')}</option>
//                                     <option value="">{t('Australia')}</option>
//                                     <option value="">{t('Canada')}</option>
//                                     <option value="">{t('China')}</option>
//                                     <option value="">{t('Germany')}</option>
//                                     <option value="">{t('United Kingdom')}</option>
//                                     <option value="">{t('Russia')}</option>
//                                 </select>
//                             </div>

//                                 placeholder={t('*Describe Your Requirements')}
//                                 {...register("message", {
//                                     required: t("Enter your Message"),
//                                 })}
//                                 className="shadow placeholder:text-white focus:ring-0 focus:border-white text-white appearance-none border border-white bg-blue-800 rounded w-[100%] py-1 px-2 "
//                             >
//                                 {errors.message && (
//                                     <div className="mt-1 text-red-600">
//                                         <small>{errors.message.message}</small>
//                                     </div>
//                                 )}
//                             </textarea>
//                         </div>
//                   
// {t('Submit')}</button>

//         






import React, { useState, useEffect } from 'react';
import QueryFormImg from "../../../../assets/work-from-home.png";
import "react-international-phone/style.css";
import "./queryform.css";
import AOS from 'aos';
import 'aos/dist/aos.css';
import emailjs from '@emailjs/browser';
import Logo from "../../../../assets/footerlogo.png";
import Logo2 from "../../../../assets/whtspicon.png";
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';



function QueryForm() {
    const { t } = useTranslation();
    useEffect(() => {
        AOS.init({
            disable: "phone",
            duration: 700,
            easing: "ease-out-cubic",
        });
    }, []);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        organization: '',
        product: '',
        country: '',
        message: '',
    });

    const [isPopupVisible, setIsPopupVisible] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs
            .sendForm(
                'service_dcad3wn',
                'template_inezejc',
                e.target,
                '8gcIElKdyO29BrDwj'
            )
            .then(
                (result) => {
                    console.log(result.text);
                    setIsPopupVisible(true);
                    setFormData({
                        name: '',
                        email: '',
                        phone: '',
                        organization: '',
                        product: '',
                        country: '',
                        message: '',
                    });
                    setTimeout(() => setIsPopupVisible(false), 7000);
                },
                (error) => {
                    console.log(error.text);
                    alert('Failed to send message. Please try again.');
                }
            );
    };

    return (
        <>
            <div className="bg-blue-500 min-h-fit grid grid-cols-12 justify-center">
                <div data-aos="fade-right" className="w-[80%] lg:col-span-6 sm:col-span-12 mx-auto p-10">
                    <div className="query-img h-[80%] w-[80%]">
                        <img src={QueryFormImg} alt="" />
                    </div>
                </div>
                <div data-aos="fade-left" className="query-form-text mt-12 lg:col-span-6 sm:col-span-12 sm:pb-4 sm:ml-5">
                    <h4 data-aos="flip-left" className="text-4xl font-bold text-white w-[90%]">{t("Let's Connect With Us")}</h4>
                    <form autoComplete="off" className="space-y-4 mt-10 w-[80%] mx-auto" onSubmit={sendEmail}>
                        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-3">
                            <div>
                                <input
                                    type="text"
                                    name="name"
                                    placeholder={t('*Your Name')}
                                    className="shadow placeholder-white appearance-none focus:ring-0 focus:border-white name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder={t("*E-Mail")}
                                    className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none border border-white bg-blue-800 rounded w-[100%] py-3 px-2 text-white leading-tight focus:outline-none focus:shadow-outline"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="grid lg:grid-cols-2 sm:grid-cols-1 gap-3">
                            <div>
                                <input
                                    type="tel"
                                    name="phone"
                                    placeholder={t("*Your Phone Number")}
                                    className="shadow placeholder:text-white focus:ring-0 focus:border-white text-white appearance-none border border-white bg-blue-800 rounded w-[100%] py-[10px] px-2"
                                    value={formData.phone}
                                    onChange={handleChange}

                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="organization"
                                    placeholder={t("*Organization Name")}
                                    className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
                                    value={formData.organization}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="product"
                                    placeholder={t("*Products/Service Looking For")}
                                    className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
                                    value={formData.product}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <select
                                    name="country"
                                    className="shadow placeholder-white focus:ring-0 focus:border-white appearance-none name-input-box border border-white bg-blue-800 rounded w-[100%] py-3 px-2 leading-tight text-white focus:outline-none focus:shadow-outline"
                                    value={formData.country}
                                    onChange={handleChange}
                                    required
                                >
                                    <option value="">{t('*Select Your Country')}</option>
                                    <option value="India">{t("India")}</option>
                                    <option value="United States">{t('United States')}</option>
                                    <option value="Japan">{t('Japan')}</option>
                                    <option value="Australia">{t('Australia')}</option>
                                    <option value="Canada">{t('Canada')}</option>
                                    <option value="China">{t('China')}</option>
                                    <option value="Germany">{t('Germany')}</option>
                                    <option value="United Kingdom">{t('United Kingdom')}</option>
                                    <option value="Russia">{t('Russia')}</option>
                                </select>
                            </div>
                        </div>
                        <div className="grid grid-cols-1">
                            <textarea
                                name="message"
                                placeholder={t("*Describe Your Requirements")}
                                className="shadow placeholder:text-white focus:ring-0 focus:border-white text-white appearance-none border border-white bg-blue-800 rounded w-[100%] py-1 px-2"
                                value={formData.message}
                                onChange={handleChange}
                                required
                            />
                        </div>
                        <div className="latestproduct-button text-center">
                            <button type="submit" className="more-product-btn px-8 py-3 text-base">
                               {t("Submit")}
                            </button>
                        </div>
                    </form>

                </div>
            </div>
            {isPopupVisible && (
                <div class=" popup-message fixed inset-0 flex items-center justify-center">
                    <div class="bg-white rounded-lg w-full max-w-sm p-6 relative">
                        <button class="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
                            onClick={() => setIsPopupVisible(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <div class="flex justify-center mb-4">
                            <img class="w-20 h-20 border border-black rounded-full p-2" src={Logo} alt="Profile Image" />
                        </div>
                        <h2 class="text-center text-xl font-semibold text-gray-900 ">Thank You For Contacting!</h2>
                        <h2 class="text-center text-xl font-semibold text-gray-900 mb-2">RC Creation</h2>
                        <p class="text-center text-gray-600 ">
                            We will get back to you shortly!
                        </p>
                        <p class="text-center text-gray-600 mb-6">
                            Have a Nice Day.
                        </p>
                        < Link to={"https://web.whatsapp.com/919310452760"} target="_blank" >
                            <button class="flex items-center justify-center w-full py-2 px-4 mb-2 border rounded-full text-gray-700 bg-white hover:bg-gray-100 border-gray-300">
                                <img src={Logo2} alt="Google Icon" class="w-7 h-7 mr-5" />
                                Connect with Whatsapp
                            </button>
                        </Link>
                    </div>
                </div>
            )}
        </>
    );
}

export default QueryForm;